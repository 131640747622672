#welcome_animation {
    position: fixed;
    background-color: black;
    bottom: 0vh;
    right: 0vw;
    width: 100%;
    height: 100%;
    z-index: 9999;
    animation: moveUp 2.5s ease forwards;
    animation-delay: 2.5s;
}

.no-scroll {
    overflow: hidden;
}

.letters {
    transform: translate(-15%, -20%);
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-140%);
    }
}