.header {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    z-index: 9998;
    display: flex;
    align-items: center;
    height: 6vh;
}

.right_header {
    position: absolute;
    right: 6rem;
}

.header.dark {
    background-color: black;
    color: white;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 60px;
    height: auto;
    margin-right: 10px;
}

.nameblock {
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.controls {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.5rem;
    gap: 1.5rem;
}

.toggle-container {
    width: 60px;
    height: 30px;
    background-color: #f0f2f4 !important;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    cursor: pointer;
    position: relative;
    font-size: 0.9rem;
}

.toggle-container.dark {
    background-color: rgb(22, 22, 22) !important;
}

.icon-button-dark,
.icon-button-light {
    background: transparent !important;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    color: rgb(0, 0, 0) !important;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 25px;
    height: 25px;
}

.icon-button-dark {
    position: absolute;
    right: 0.4rem;
}

.icon-button-light {
    position: absolute;
    left: 0.4rem;
}

.icon-button-dark.dark,
.icon-button-light.dark {
    color: rgb(255, 255, 255) !important;
}

.toggle-ball-language,
.toggle-ball {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 40%;
    transition: transform 0.5s ease;
    z-index: 0;
}


.toggle-ball-language.dark,
.toggle-ball.dark {
    background-color: rgb(0, 0, 0);
}

.light .toggle-ball,
.toggle-ball-language.en {
    transform: translate(0, -50%);
}

.dark .toggle-ball {
    transform: translate(31px, -50%);
}

.toggle-ball-language.de {
    transform: translate(30px, -50%);
}

.en-button,
.de-button {
    z-index: 1;
}

.de-button {
    position: absolute;
    right: 0.6rem;
}

.en-button {
    position: absolute;
    left: 0.6rem;
}

/*
Menu
*/

.menu_button {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    top: 30%;
}

.menu_text {
    padding-right: 15px;
    font-size: 30px;
}


.menu_toggle {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
}

.menu_toggle::after,
.menu_toggle::before {
    content: '';
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    left: 0;
    right: 0;
    transition: all ease-out 0.3s;
}

.menu_button.light .menu_text {
    color: black;
}

.menu_toggle.light::after,
.menu_toggle.light::before {
    border-bottom: 3px solid black;
}

.menu_button.dark .menu_text {
    color: white;
}

.menu_toggle.dark::after,
.menu_toggle.dark::before {
    border-bottom: 3px solid white;
}


.menu_toggle::after {
    top: 100%;
}

.menu_toggle::before {
    transform-origin: center;
}

.menu_toggle i {
    display: block;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 3px;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 50%;
    transition: all ease-out 0.1s;
}

.menu_toggle.light i {
    background-color: black;
}

.menu_toggle.active-menu::after {
    transform: rotate(-45deg);
    top: 50%;
}

.menu_toggle.active-menu::before {
    transform: rotate(45deg);
    top: 50%;
}

.menu_toggle.active-menu i {
    opacity: 0;
}

#menu_view {
    position: fixed;
    width: 100%;
    left: 0%;
    bottom: 0%;
    background-color: #080808;
    transform: translateY(100%);
    transition: transform 0.7s ease;
    z-index: -1;
}

#menu_view.show {
    transform: translateY(0%);
}

#menu_view a {
    text-decoration: none;
}

#menu_view.dark a {
    color: white;
}

#menu_view.light {
    background-color: white;
}

#menu_view.light a {
    color: black;
}

#menu_view ul {
    transform: translateY(40%);
    display: flex;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding: 0;
    height: 100vh;
}

#menu_view ul li {
    list-style: none;
    margin: 10px 0;
    font-size: 40px;
    margin-bottom: 15px;
    cursor: pointer;
}

#menu_view.dark ul li {
    color: white;
}

#menu_view.light ul li {
    color: #080808;
}


@media (max-width: 768px) {
    .header {
        height: auto;
        padding: 0px;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .right_header {
        position: absolute;
        right: 0rem;
    }

    .controls {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.6rem;
    }

    .logo {
        width: 40px;
        height: auto;
        margin-right: 5px;
    }

    .nameblock {
        font-size: 20px;
    }

    .menu_text {
        font-size: 20px;
        padding-right: 8px;
    }

    .menu_button {
        display: flex;
        align-items: center;
        flex-direction: row;
        top: 30%;
        margin-right: 20px;
    }

    .toggle-container {
        width: 42px;
        height: 28px;
        font-size: 0.7rem;
    }

    .toggle-container.dark {
        background-color: rgb(22, 22, 22) !important;
    }

    .icon-button-dark,
    .icon-button-light {
        width: 16px;
        height: 16px;
    }

    .toggle-ball-language,
    .toggle-ball {
        left: 3px;
        top: 51%;
        transform: translateY(-50%);
        width: 1.4rem;
        height: 1.4rem;
    }

    .de-button {
        position: absolute;
        right: 0.4rem;
    }

    .en-button {
        position: absolute;
        left: 0.4rem;
    }


    .toggle-ball-language.dark,
    .toggle-ball.dark {
        background-color: rgb(0, 0, 0);
    }

    .light .toggle-ball,
    .toggle-ball-language.en {
        transform: translate(0, -50%);
    }

    .dark .toggle-ball {
        transform: translate(22px, -50%);
    }

    .toggle-ball-language.de {
        transform: translate(21.5px, -50%);
    }

    .back-button {
        text-decoration: none;
        color: inherit;
        width: 40px;
        font-size: 20px;
    }

}

.back-button {
    text-decoration: none;
    color: inherit;
    width: 80px;
}