.contact_section.light,
.contact_section.dark {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 65vh;
    padding: clamp(1em, 4vw, 2em);
    margin-top: 10vh;
}

#motto_container {
    display: flex;
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
}

.profile_image {
    border-radius: 50%;
    width: 10rem;
    height: auto;
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
}

.motto.dark,
.motto.light {
    text-align: left;
}

.motto.dark h3,
.motto.dark p {
    color: inherit;
    font-size: clamp(15px, 2vw, 30px);
    margin: 0;
    line-height: 1.5;
}

.motto.light h3,
.motto.light p {
    color: #333;
    font-size: clamp(15px, 2vw, 30px);
    margin: 0;
    line-height: 1.5;
}

.divider {
    width: 80%;
    border: none;
    border-top: 1px solid #ddd;
    margin-bottom: 30px;
}

.contact_buttons.dark,
.contact_buttons.light {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;
    overflow: hidden;

}

.contact_buttons.light h3,
.contact_buttons.dark h3 {
    font-size: 25px;
    margin-bottom: 22px;
}

.contact_buttons.light h3 {
    color: #333;
}

.contact_buttons.dark h3 {
    color: inherit;
}

.button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.contact_button.dark,
.contact_button.light {
    display: inline-block;
    padding: 15px 40px;
    text-decoration: none;
    border-radius: 100px;
    font-size: 25px;
    transition: background-color 0.4s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
}

.contact_button.dark {
    background-image: repeating-linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    color: black;
}

.contact_button.light {
    background-image: linear-gradient(rgb(59, 59, 59), rgb(36, 36, 36));
    color: white;
}

.contact_button.email:hover,
.contact_button.phone:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}

.contact_button:active {
    background-image: linear-gradient(rgb(50, 50, 50), black);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
}


.fade-in-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-element.visible {
    opacity: 1;
    transform: translateY(0);
}

.contact_button.email,
.contact_button.phone {
    opacity: 1;
    transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.contact_button.email {
    transform: translateX(-16vw);
}

.contact_button.phone {
    transform: translateX(16vw);
}

.contact_button.visible {
    transform: translateX(0);
    opacity: 1;
}

.divider.fade-in-element {
    transform: scaleX(0);
}

.divider.fade-in-element.visible {
    transform: scaleX(1);
}


@media only screen and (max-width: 900px) {
    .profile_image {
        margin-bottom: 20px;
    }

    #motto_container {
        flex-direction: column;
    }

    .button_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }


    .contact_buttons.light h3,
    .contact_buttons.dark h3 {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    .contact_button.dark,
    .contact_button.light {
        display: inline-block;
        padding: 15px 40px;
        font-size: 1rem;
        transition: background-color 0.4s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
        width: 230px;
    }
}