.projects-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 12vh;
}

#projects_anchor {
    position: absolute;
    margin-top: 20rem;
}


.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    margin-top: 0;
    row-gap: 80px;
    column-gap: 100px;
    padding-left: 100px;
    padding-right: 100px;
}



/* Dark-Modus Styles */
.projects-container.dark {
    background-color: black;
    color: #e0e0e0;
}

/* Light-Modus Styles */
.projects-container.light {
    background-color: #ffffff;
    color: #121212;
}

.project-card {
    position: relative;
    width: 530px;
    height: 750px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 0.6s ease-out forwards;
}

.project-card.dark {
    background-image: linear-gradient(to bottom, rgba(49, 49, 49, 0.9), rgb(0, 0, 0));
    box-shadow: 0px 4px 10px rgba(211, 211, 211, 0.6);
}

.project-card.light {
    background-color: white;
    box-shadow: 0 4px 10px rgba(31, 31, 31, 0.6);
}

.project-card.light:hover,
.project-card.dark:hover {
    transform: translateY(-10px) scale(1.01);
}

.project-image {
    height: 200px;
    background-size: cover;
    background-position: center;
}

.project-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.project-icon img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #e0e0e0;
}

.project-content {
    flex: 1;
    overflow: hidden;
}

.project-title {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.project-year {
    font-size: 0.9rem;
}

.project-description {
    margin: 10px 0;
    height: 120px;
}

.project-cooperation {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
}

.project-technologies {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
}

.technologies {
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 150px;
    align-items: center;
    align-self: center;
    gap: 20px;
}

.tech-icon {
    width: 35px;
    height: 35px;
}

.project-footer {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    bottom: 20px;
}

.project_link {
    display: inline-flex;
    padding: 0px 25px;
    background-color: #242938;
    color: white;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.3s ease;
    align-items: center;
}

.project_link:hover {
    transform: translateY(-3px);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}


.lht-logo {
    margin-top: -5rem;
    width: 15rem;
    height: auto;
    margin-bottom: -7rem;
}

.tchibo-logo {
    margin-top: 1rem;
    width: 5rem;
    height: auto;
    margin-bottom: -0.3rem;
}

.cau-logo {
    margin-top: 1.5rem;
    width: 8rem;
    height: auto;
    margin-bottom: -0.6em;
}

.adesso-logo {
    margin-top: 0.5rem;
    width: 8rem;
    height: auto;
    margin-bottom: -1.7em;
}


@media (max-width: 768px) {
    .projects-wrapper {
        margin-top: 8vh;
        padding: 10px;
    }

    .projects-container {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        row-gap: 40px;
        column-gap: 0px;
        margin-top: 0;
    }

    .project-card {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }

    .project-image {
        height: 150px;
    }

    .project-title {
        font-size: 1.2rem;
    }

    .project-description {
        height: auto;
        font-size: 0.9rem;
    }

    .project-technologies {
        gap: 5px;
        margin-bottom: 5px;
    }

    .technologies {
        max-width: 100%;
        justify-content: center;
        gap: 10px;
    }

    .tech-icon {
        width: 30px;
        height: 30px;
    }

    .project-footer {
        position: relative;
        margin-top: 15px;
        bottom: 0px;
    }

    .project_link {
        font-size: 0.9rem;
        padding-right: 0px;
        padding-left: 18px;
        width: 8rem;
        height: 40px;
    }

    .github_logo {
        width: 38px;
    }
}


/*
------------------------------ Fade in animation 
*/

.fade-in-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-element.visible {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
}