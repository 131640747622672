.about-me-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: var(--background-color, #f4f4f9);
    padding-top: 15vh;
    box-sizing: border-box;
}

.about-me-container.dark {
    color: #ffffff;
}

.about-me-container.light {
    --background-color: #ffffff;
}

.about-me-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    height: auto;
    background-color: var(--content-background, #ffffff);
    padding: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    transition: background-color 0.3s ease;
}

.about-me-content.dark {
    background-image: linear-gradient(to bottom, rgba(49, 49, 49, 0.9), rgb(0, 0, 0));
    box-shadow: 0px 1px 10px rgba(211, 211, 211, 0.6);
}

.me-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.about-me-image {
    flex-shrink: 0;
    margin-right: 40px;
}

.profile-pic {
    width: 220px;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: transparent;
}


.about-me-text {
    margin-top: -30px;
    align-self: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    letter-spacing: 1.5px;
    font-weight: 600;
}




.name {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 20px;
}

.role {
    font-size: 1.25rem;
}

.location {
    font-size: 1.1rem;
}


.button_container {
    align-self: center;
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.ref_contact {
    text-decoration: none;
}


.resume_button,
.contact_ref_button {
    align-items: center;
    background-image: linear-gradient(rgb(59, 59, 59), rgb(36, 36, 36));
    border: 0;
    font-family: Phantomsans, sans-serif;
    display: inline-block;
    padding: 12px 40px;
    text-decoration: none;
    border-radius: 100px;
    font-size: 25px;
    transition: background-color 0.4s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 100px;
    box-sizing: border-box;
    color: #FFFFFF;
    line-height: 1em;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.4s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.4s ease-in-out;
}

.resume_button.dark,
.contact_ref_button.dark {
    background-image: repeating-linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    color: black;
}

.resume_button.light,
.contact_ref_button.light {
    color: #FFFFFF;
}

.resume_button:hover,
.contact_ref_button:hover {
    background-image: linear-gradient(rgb(85, 85, 85), black);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}

.resume_button.dark:hover,
.contact_ref_button.dark:hover {
    background-image: linear-gradient(rgb(221, 221, 221), rgb(255, 255, 255));
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}

.resume_button:active,
.contact_ref_button:active {
    background-image: linear-gradient(rgb(50, 50, 50), black);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
}

.ref_contact {
    color: white;
    text-decoration: none;
}

.button-63:active,
.button-63:hover {
    outline: 0;
}

.point-icon {
    color: #000000;
}

.about-me-container.dark .point-icon {
    color: #ffffff;
}

.about-me-points {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.point {
    display: flex;
    align-items: center;
}

.point-icon {
    font-size: 2rem;
    margin-right: 1rem;
}

.point-text h3 {
    margin: 0;
    font-size: 1.3rem;
}

.point-text p {
    margin: 5px 0;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .about-me-container {
        flex-direction: column;
        padding: 10px;
        padding-top: 15vh;
    }

    .about-me-content {
        padding: 20px;
        width: 80%;
        max-width: 90%;
    }

    .me-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .about-me-image {
        margin-right: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    .profile-pic {
        width: 140px;
    }

    .about-me-text {
        text-align: center;
    }

    .name {
        font-size: 1.5rem;
    }

    .role {
        margin-top: -10px;
        font-size: 0.9rem;
    }

    .location {
        margin-top: -5px;
        font-size: 0.9rem;
    }

    .button_container {
        flex-direction: row;
        gap: 0.5rem;
    }

    .resume_button,
    .contact_ref_button {
        font-size: 0.8rem;
        width: 140px;
        text-align: center;
        margin: 0 auto;
    }

    .about-me-points {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-top: 5px;
    }

    .point {
        display: flex;
        align-items: center;
    }

    .point-icon {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
    }

    .point-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 2px;
        margin-left: 32px;
    }



    .point-text h3,
    .point-text p {
        font-size: 0.7em;
        line-height: 1.2;
        margin: 0;
    }

    .point-text p {
        margin-top: 2px;
    }
}





/*
---------------------- Tech Stack ------------------------------
*/
.technologies_container {
    background-color: transparent !important;
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 50px;
}

.technologies_container h2 {
    position: relative;
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    letter-spacing: 1.5px;
    left: 0px;
    margin-right: 30px;
}

.technologies_container.dark h2 {
    color: white;
}

.vertical-line {
    width: 2px;
    height: 80px;
    background-color: #333;
    margin-right: 30px;
}

.tech_stack {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: transparent !important;
}

.tech_stack div img {
    width: 55px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-right: 5px;
}

.pair {
    display: inline-flex;
}

.pair img {
    background-color: transparent !important;
    border-radius: 20%;
}

.tech_stack li {
    list-style: none;

}

.tech_stack ul {
    cursor: pointer;
}


.tech_stack a:nth-child(2n) {
    margin-right: 4ch;
}

.tech_stack img:hover {
    transform: scale(1.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/*
-----------------------Media-----------------------
*/
@media (max-width: 768px) {
    .technologies_container {
        flex-direction: column;
        margin-top: 8px;
    }

    .tech_stack {
        width: 120%;
        flex-direction: column;
        gap: 3rem;
        height: auto;
        text-align: center;
        margin: 0px;
    }

    .tech_stack div img {
        width: 45px;
        height: auto;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        margin-top: 2px;
    }

    .technologies_container h2 {
        margin-right: 0px;
        font-size: 1.5rem;
    }

    .vertical-line {
        display: none;
    }
}