#footer {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    bottom: 10px;
}

.footer-title {
    font-size: 0.6rem;
    color: #8d8e8f;
}

#footer_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


#footer_left,
#footer_right {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
}

#footer_right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.footer_value {
    font-size: 1rem;
}

#social_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#social_container p {
    text-align: right;
    margin-bottom: 5px;
}

#social_links {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 10px;
}

#social_links li {
    display: inline;
}

#social_links a {
    color: inherit;
    text-decoration: none;
    position: relative;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.1s ease-out;
}

#social_links a::before,
#social_links a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    background-color: #333;
    transition: width 0.4s ease-out;
}

#social_links a::before {
    left: 50%;
}

#social_links a::after {
    right: 50%;
}

#social_links a:hover::before,
#social_links a:hover::after {
    width: 50%;
}

#social_links a:hover {
    color: #000;
}


#footer p {
    margin: 5px 0;
}

.version,
.local_time {
    display: flex;
    flex-direction: column;
    padding-right: clamp(1em, 4vw, 2em);
}


#copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
}

.imprint_ref {
    align-self: center;
    color: inherit;
    text-decoration: none;
}

@media only screen and (max-width: 900px) {
    #footer_content {
        flex-direction: column;
        align-items: flex-start;
    }

    #footer_right {
        margin-left: 0;
    }

    #social_container {
        flex: 1;
        width: 80%;
        border: none;
        margin-bottom: 10px;
        text-align: center;
    }

    #footer_content::after {
        content: '';
        width: 100%;
        border-top: 1px solid #ddd;
        margin-bottom: 20px;
    }

    #footer_left {
        order: 2;
        margin-top: 30px;
        width: 100%;
        justify-content: space-between;
    }

    #copyright {
        order: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .local_time {
        align-self: flex-end;
        padding: 0px;
    }

    .footer_value {
        font-size: 0.8rem;
    }


}