html,
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.light {
    background-color: white;
    color: black;
}

.dark {
    background-color: black;
    color: white;
}

body.dark {
    background-color: black;
    color: white;
}

body.light {
    background-color: white;
    color: black;
}