.imprint {
    position: relative;
    top: 8vh;
    margin-bottom: 4vh;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    height: auto;
    width: 50%;
    margin-bottom: 50px;
}

.home_text {
    text-decoration: none;
    padding-right: 15px;
    font-size: 30px;
    color: white;
}

.imprint_mail {
    color: inherit;
    text-decoration: none;
}

.disclaimers {
    display: flex;
    flex-direction: column;
}

.disclaimers strong {
    position: relative;
    top: 15px;
}

@media (max-width: 768px) {
    .home_text {
        font-size: 20px;
    }

    .imprint {
        width: 80vw;
        font-size: 0.9rem;
    }
}